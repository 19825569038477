import * as React from "react";
import classnames from "classnames";
import queryString from "query-string";
import { setData } from "@fitplan/context/lib-es/auth/storage";

import { Layout } from "../layout";
import { CookieConsent } from "../CookieConsent";
import * as config from "../../config";

import "../../global/index.scss";

export interface Props {
    className?: string;
    hideBanner?: boolean;
}

const handleBranchLink = () =>
    (window as any).branch.init(config.branchLinkApiKey, function(
        _: Error,
        data: {
            referring_link?: string;
            data_parsed?: { "~referring_link": string };
        }
    ) {
        console.log("Found branch link", data?.data_parsed);
        if (data?.data_parsed?.["~referring_link"]) {
            setData({
                branchLink: {
                    value: data.data_parsed["~referring_link"],
                    created: new Date(),
                },
            });
        }
    });

class PageWrapper extends React.Component<Props> {
    componentDidMount() {
        if (typeof window === "undefined") return;
        const query = queryString.parse(window.location.search);
        if (query && query["_branch_match_id"]) {
            try {
                handleBranchLink();
            } catch (err) {
                /// If the branch link SDK is slow to load, wait a bit and try again
                setTimeout(handleBranchLink, 10);
            }
        }
    }

    render() {
        const { children, className, ...props } = this.props;
        return (
            <Layout {...props}>
                <CookieConsent />
                <div className={classnames("app-content", className)}>
                    {children}
                </div>
            </Layout>
        );
    }
}

export default PageWrapper;
